import mitt from 'mitt';

// 创建一个 mitt 实例
const emitter = mitt();

// 定义一个获取 emitter 的函数
function getEmitter() {
    return emitter;
}

// 导出 getEmitter 函数
export { getEmitter };